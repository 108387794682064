import React from "react";

import Rating from "../Rating/Rating";
import "./Question.css";
import NewRating from "../Rating/Rating";

type objType = {
  [key: string]: number;
};

type Props = {
  ratings: objType;
  question: string;
  questionId: string;
  onRateChange: Function;
};

const Question = (props: Props) => {
  const { questionId, question, onRateChange, ratings } = props;

  function onRatingChange(rating: number) {
    onRateChange(rating, questionId);
  }

  const FullSymbol = () => {
    return (
      <div className="rating-full fa fa-star fa-2x ml-2 mr-2 green-star">
        <div />
      </div>
    );
  };
  
  const EmptySymbol = () => {
    return (
      <div className="rating-empty fa fa-star fa-2x ml-2 mr-2 grey-star">
        <div />
      </div>
    );
  };
  return (
    <div className="card mt-3">
      <div className="card-body">
        <p className="question-title">{question}</p>
        <p className="choose-text">(1 Strongly Disagree - 5 Strongly Agree)</p>
        <div className="mt-5 mb-5 rating">
          <NewRating
            fullSymbol={<FullSymbol />}
            emptySymbol={<EmptySymbol />}
            onChange={onRatingChange}
            initialRating={ratings[questionId] || 0}
          />
        </div>
      </div>
    </div>
  );
};

export default Question;

import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Layout from "./components/Layout";
import AboutPage from "./pages/AboutPage";
import CareersPage from "./pages/CareersPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import RatingPage from "./pages/RatingPage";
import { useEffect } from "react";

// Component to handle programmatic redirection to Feedback
const RedirectToFeedbackWithParam = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Make sure we're not at the homepage or other predefined paths before redirecting
    const path = location.pathname.substr(1); // Exclude the leading slash

    // Assuming paths for predefined routes are not parameters for feedback
    const predefinedPaths = ["about", "contact", "careers", "terms", "privacy"];
    if (!predefinedPaths.includes(path)) {
      navigate(`/feedback/${path}`, { replace: true });
    }
  }, [location, navigate]);

  return null; // This should be acceptable as a return value for a React component
};

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="careers" element={<CareersPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="feedback/:activityCode" element={<RatingPage />} />
          <Route path="*" element={<RedirectToFeedbackWithParam />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

import react, { ChangeEvent } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../../index.css";

type FormState = {
    email: string;
    message: string;
    name: string;
}

type ServiceMessage = {
    message: string,
    class: string
}

const ContactUs = () => {
    const formId = 'gPFGUnmqA';
    const formSparkUrl = `https://submit-form.com/${formId}`;
    const recaptchaKey = '6Ld0SNApAAAAAIx79KNewliomyZtCZQfqI3nUb8k';
    const recaptchaRef = react.useRef<any>();

    const initialFormState = {
        email: '',
        message: '',
        name: ''
    }

    //States
    const [message, setMessage] = react.useState<ServiceMessage>({ message: '', class: '' });
    const [submitting, setSubmitting] = react.useState<boolean>(false);
    const [formState, setFormState] = react.useState<FormState>(initialFormState);
    const [recaptchaToken, setRecaptchaToken] = react.useState<string>('');

    const updateRecaptchaToken = (token: string | null) => {
        setRecaptchaToken(token as string);
    }

    const submitForm = (e: any) => {
        e.preventDefault();
        setSubmitting(true);
        postSubmission(formState);
    }

    const postSubmission = async (data: FormState) => {

        try {
            let response = await fetch(formSparkUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    data,
                    'g-recaptcha-response': recaptchaToken
                }),
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            setMessage({ message: 'Form submitted successfully', class: 'success' })
            setFormState(initialFormState);
            recaptchaRef.current.reset();
        } catch (error) {
            console.error(error);
            setMessage({ message: 'Failed to submitted form', class: 'failed' })
        }
    }

    return (
        <div className="container">
            <div className="hero">
                <div className="hero__content basic-flex--center" style={{ maxWidth: "400px" }}>
                    <h2 >
                        Contact Us
                    </h2> <br />

                    <form onSubmit={submitForm} >
                        <div className="form-group" >
                            <label htmlFor="name">Name</label>
                            <input style={{ width: "100%" }}
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                value={formState.name}
                                onChange={(e) => setFormState({ ...formState, name: e.target.value })}
                                required />

                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                style={{ width: "100%" }}
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                value={formState.email}
                                onChange={(e) => setFormState({ ...formState, email: e.target.value })}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                style={{ width: "100%" }}
                                rows={5}
                                id="message"
                                name="message"
                                className="form-control"
                                value={formState.message}
                                onChange={(e) => setFormState({ ...formState, message: e.target.value })}
                                required />
                        </div>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={recaptchaKey}
                            onChange={updateRecaptchaToken}
                        />
                        <br />
                        <button type="submit" className="custom-btn custom-btn--center" disabled={submitting}>
                            {message.message == '' ? 'Submit' : message.message}!
                        </button>
                    </form>
                </div>
            </div >
        </div >
    );
};

export default ContactUs;
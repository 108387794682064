import config from "../config.json";

function getMobileOperatingSystem(): string {
  const userAgent: string =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return config["FEEDBACK-UI-APPSTORE-URL"];
  }
  // Android detection
  else if (/android/i.test(userAgent)) {
    return config["FEEDBACK-UI-PLAYSTORE-URL"];
  }

  return config["FEEDBACK-UI-APPSTORE-URL"];
}
const DownloadApp = () => {
  return (
    <section className="download-app-area">
      <div className="container">
        <div className="download-app">
          <h2>Sign up for free now.</h2>
          <button className="custom-btn custom-btn--center">
            <a
              className="downloadApp"
              href={getMobileOperatingSystem()}
              target="_blank"
            >
              Download Free App
            </a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;

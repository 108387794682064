const AboutPage = () => {
    return (
    <div className="container">
        <div className="hero">        
            <div className="hero__content">
            <br />
            <br />
            <p>
                We are a Sydney, Australia based organisation and our mission with Kata Life is to develop a platform 
                to help people get better at presenting, public speaking, running workshops, engineering, or any skills using 
                actionable human feedback from their peers or audiences. 
            </p><br /><p>
                We make it easy to get feedback and give feedback using simple and well designed rating question banks and smart 
                selection of questions based on your skill level. 
            </p>
            <br /><p>
                We are actively adding new features and expanding our use cases. Reach out to us for integrating our feedback 
                systems with your current apps. 
            </p>
            </div>
        </div>
    </div>
    );
};

export default AboutPage;

import React from "react";
import "./MeetingHeader.css";

type Props = {
  text: string;
  lastName: string;
  firstName: string;
  description: string;
  activityType: string;
  activityDateTime: string;
  avatar: string;
  feedbackCount: string;
  activityCount: string;
  level: string;
};

const MeetingHeader = (props: Props) => {
  return (
    <div className="card mt-3 card-shadow mx-3">
      <div className="card-body m-3">
        <div className="row">
          <div className="col-md-8">
            <h6 className="text-left meeting-eventTitle">{props.text}</h6>
            <p className="text-left">{props.description}</p>
          </div>
          <div className="col-md-4 text-left">
            <div>
              <img className="profileImg"
                src={props.avatar}
               
                height="30px"
                width="30px"
              />
              <span className="ml-2 name name-bold">{props.firstName}</span>
              <span className="ml-2 name name-bold">{props.lastName}</span>
            </div>
            <div className="ml-32 mt-1">
            <span className="ml-2 name">Level</span>
            <span className="ml-2 name green-text name-bold">{props.level}</span>
            
            </div>
            <div className="ml-32 mt-1">
            <span className="ml-2 name">{props.activityType}(s)</span>
            <span className="ml-2 name green-text name-bold">{props.activityCount}</span>
            
            </div>
            <div className="ml-32 mt-1">
            <span className="ml-2 name">Feedbacks Received</span>
            <span className="ml-2 name green-text name-bold">{props.feedbackCount}</span>
            
            </div>
          </div>
          <div className="col-md-8">
            <div className="activity-items">
              <div className="col-md-10">
                <svg
                  width="30"
                  height="30"
                  fill="none"
                  viewBox="0 0 28 25"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 0C5.38293 0 0 5.38293 0 12C0 18.6171 5.38293 24 12 24C18.6171 24 24 18.6171 24 12C24 5.38293 18.6171 0 12 0ZM17.707 18.2069C17.512 18.4019 17.256 18.5001 17.0001 18.5001C16.7441 18.5001 16.4879 18.4019 16.2931 18.2069L11.293 13.207C11.105 13.0201 11.0001 12.7659 11.0001 12.5001V6C11.0001 5.44702 11.4479 5.00006 12 5.00006C12.5521 5.00006 12.9999 5.44702 12.9999 6V12.0861L17.707 16.793C18.098 17.1841 18.098 17.816 17.707 18.2069Z"
                  fill="#1A6F3F"/>
              </svg>

              <span className="ml-2 align-center">{props.activityDateTime}</span>
           
              
              </div>
              <div className="col-md-10">
              <svg
                width="30"
                height="30"
                fill="none"
                viewBox="0 0 28 25"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeWidth="3"
                  stroke="#1A6F3F"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M26 12.6667H20.6667L16.6667 23.3333L11.3333 2L7.33333 12.6667H2"/>
              </svg>
              <span className="ml-2  align-center">{props.activityType}</span>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingHeader;

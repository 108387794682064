import ContactUs from "../components/ContactUs/ContactUs";

const ContactPage = () => {
    return (<main>
        <ContactUs />
    </main>
    );
};

export default ContactPage;

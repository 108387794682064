import config from "../config.json";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";

function getMobileOperatingSystem(): string {
  const userAgent: string =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return config["FEEDBACK-UI-APPSTORE-URL"];
  }
  // Android detection
  else if (/android/i.test(userAgent)) {
    return config["FEEDBACK-UI-PLAYSTORE-URL"];
  }

  return config["FEEDBACK-UI-APPSTORE-URL"];
}

const Hero = () => {
  const [activityCode, setactivityCode] = useState("");
  const navigate = useNavigate();

  const handleNavigate = () => {
    debugger;
    if (activityCode) {
      // Navigate to the user profile page with the userId as a parameter
      navigate(`/feedback/${activityCode}`);
    } else {
      toast.error(config["FEEDBACK-UI-INPUT-VALIDATION-MESSAGE"], {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        bodyClassName:"toast-body",
        transition: Slide,
        });
    }
  };
  return (
    <section className="hero-area">
      <div className="hero-circle"></div>

      <div className="container">
        <div className="hero">
          <div className="hero__content">
            <div className="activity-code-container">
              <label>Have an activity code?</label>
              <div className="activity-code-container-inner">
              <input
                type="text"
                id="activityCode"
                className="activity-code__input"
                value={activityCode}
                onChange={(e) => setactivityCode(e.target.value)}
                placeholder="Enter code here"
              />
              <button type="button" onClick={handleNavigate}>
                GO
              </button>
              </div>
              
            </div>
            <br/>
            <br/>
            <br/>
            <h1>
              Improve your skills <span>with real actionable feedback</span>
            </h1>

            <button className="custom-btn custom-btn--center">
              <a
                className="downloadApp"
                href={getMobileOperatingSystem()}
                target="_blank"
              >
                Download App
              </a>
            </button>

            {/* <img className="hero__image" src="/images/hero.png" alt="hero" /> */}
            <video controls poster="/images/hero.png"
                            className="hero__image"
                            src="/images/appvideo.mp4"
                        />
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Hero;

import axios from "axios";
import Cookies from "universal-cookie";
import { createBrowserHistory } from "history";
import { Slide, ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-toastify/dist/ReactToastify.css";
import loadingSvg from "../assets/loading.svg";
import { v4 as uuidv4 } from "uuid";
import config from "../config.json";
import { QuestionInterFace } from "../interfaces/QuestionInterface";
import Question from "../components/Question/Question";
import MeetingHeader from "../components/MeetingHeader/MeetingHeader";
import { useNavigate, useParams } from "react-router-dom";
import Activities from "../components/Activities";
import DownloadApp from "../components/DownloadApp";
import Growth from "../components/Growth";
import Progress from "../components/Progress";
import ShareFeedback from "../components/ShareFeedback";
import Hero from "../components/Hero";
import TopUI from "../components/TopUI";
type objType = {
  [key: string]: number;
};

const cookies = new Cookies();
const history = createBrowserHistory();
function getMobileOperatingSystem(): string {
  const userAgent: string =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return config["FEEDBACK-UI-APPSTORE-URL"];
  }
  // Android detection
  else if (/android/i.test(userAgent)) {
    return config["FEEDBACK-UI-PLAYSTORE-URL"];
  }

  return config["FEEDBACK-UI-APPSTORE-URL"];
}
function RatingPage() {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNotFoundError, setShowNotFoundError] = useState(false);
  const [showGenericError, setShowGenericError] = useState(false);
  const [showAlreadySubmittedError, setShowAlreadySubmittedError] =
    useState(false);
  const [showExpiredError, setShowExpiredError] = useState(false);
  const [showInputActivityCodeError, setShowInputActivityCodeError] =
    useState(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("");
  const [avatar, setAvatar] = useState("");
  const [activityType, setActivityType] = useState("");
  const [activityDateTime, setActivityDateTime] = useState("");
  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastName, setLastName] = useState("");
  const [ratings, setRatings] = useState({});
  const [feedbackCount, setfeedbackCount] = useState("");
  const [activityCount, setactivityCount] = useState("");
  const [level, setlevel] = useState("");
  const { activityCode } = useParams();
  const isData = activityCode && eventName;
  const navigate = useNavigate();
  const [guid] = useState(uuidv4);
  useEffect(() => {
    if (activityCode) {
      hideAllBoxMessages();
      let activityCodes = cookies.get("activityCodes");
      if (activityCodes && activityCodes.includes(activityCode)) {
        setShowAlreadySubmittedError(true);
      } else {
        setLoading(true);

        axios
          .get(
            `${config.KATA_LIFE_API_URL}feedback/${activityCode}/${config["FEEDBACK-UI-QUESTION-COUNT"]}`
          )
          .then((data) => {
            setEventName(data?.data?.eventName);
            setQuestions(data?.data?.questions || []);
            setFirstName(data?.data?.firstName);
            setLastName(data?.data?.lastName);
            setDescription(data?.data?.description);
            setActivityType(data?.data?.activityType);
            setActivityDateTime(data?.data?.activityDateTime);
            setAvatar(data?.data?.avatar);
            setfeedbackCount(data?.data?.feedbackCount);
            setactivityCount(data?.data?.activityCounts);
            setlevel(data?.data?.level);
          })
          .catch((error) => {
            //history.push("/");
            debugger;
            if (error.response?.status === 422) {
              setShowExpiredError(true);
            } else if (error.response?.status === 404) {
              setShowNotFoundError(true);
            } else {
              setShowGenericError(true);
            }
          })
          .finally(() => setLoading(false));
      }
    }
  }, [activityCode]);

  function submitRatings(data: any) {
    let activityCodes = cookies.get("activityCodes");
    if (activityCodes) {
      if (activityCodes.includes(activityCode)) {
        setShowAlreadySubmittedError(true);
        return false;
      } else {
        activityCodes = [...activityCodes, activityCode];
      }
    } else {
      activityCodes = [activityCode];
    }
    activityCodes = JSON.stringify(activityCodes);
    setLoading(true);

    axios
      .post(`${config.KATA_LIFE_API_URL}feedback`, {
        SessionId: guid,
        Answers: data,
        ActivityCode: activityCode,
      })
      .then((response) => {
        cookies.set("activityCodes", activityCodes, { path: "/" });
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  function handleRateChange(rating: number, id: string) {
    debugger;
    const currentIndex = questions.findIndex((item) => item.questionId === id);
    const oldRatings: objType = { ...ratings };
    oldRatings[`${questions[currentIndex].questionId}`] = rating;
    setRatings({ ...oldRatings });
    setTimeout(() => {
      if (currentIndex + 1 < questions.length) {
        setActiveQuestionIndex(currentIndex + 1);
      } else {
        submitRatings(oldRatings);
        // toast.success(
        //   config["FEEDBACK-UI-SUBMITTED-MESSAGE"],
        //   {
        //     position: "top-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: false,
        //     pauseOnHover: false,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //     bodyClassName: "toast-body",
        //     transition: Slide,
        //   }
        // );
        setShowConfirmation(true);
      }
    }, 500);
  }

  function hideAllBoxMessages() {
    setShowAlreadySubmittedError(false);
    setShowExpiredError(false);
    setShowGenericError(false);
    setShowNotFoundError(false);
    setShowInputActivityCodeError(false);
  }

  function renderQuestions() {
    return questions?.length > 0 ? (
      <Carousel
        swipeable={false}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        useKeyboardArrows={false}
        selectedItem={activeQuestionIndex}
      >
        {questions.map((item: QuestionInterFace, index: number) => (
          <Question
            key={index}
            ratings={ratings}
            question={item.questionText}
            questionId={item.questionId}
            onRateChange={handleRateChange}
          />
        ))}
      </Carousel>
    ) : (
      <></>
    );
  }

  function renderConfirmation() {
    return (
      <div className="card mt-3 card-shadow mx-3 messagebox">
        <div className="card-body text-center">
          <br />
          <div className="center activity-card middle">
            <span className="input-label">Thank you for your feedback.</span>
            <br />
            <br />
            <button className="custom-btn custom-btn--center">
              <a
                className="downloadApp"
                href={getMobileOperatingSystem()}
                target="_blank"
              >
                Download App
              </a>
            </button>
          </div>
        </div>
      </div>
    );
  }
  function renderBoxMessage(message: string, canClose: boolean) {
    return (
      <div className="card mt-3 card-shadow mx-3 messagebox">
        {canClose ? (
          <a href="/" className="close" onClick={hideAllBoxMessages}></a>
        ) : (
          ""
        )}
        <div className="card-body text-center">
          <div className="center activity-card">
            <span className="input-label">{message}</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <main>
      <section>
        <div className="container-fluid">
          <div className="card-body-carousel">
            {showAlreadySubmittedError
              ? renderBoxMessage(
                  config["FEEDBACK-UI-ALREADY-SUBMITTED-MESSAGE"],
                  true
                )
              : ""}
            {showExpiredError
              ? renderBoxMessage(config["FEEDBACK-UI-EXPIRED-MESSAGE"], true)
              : ""}
            {showNotFoundError
              ? renderBoxMessage(config["FEEDBACK-UI-NOTFOUND-MESSAGE"], true)
              : ""}
            {showGenericError
              ? renderBoxMessage(config["FEEDBACK-UI-ERROR-MESSAGE"], true)
              : ""}
            {showInputActivityCodeError
              ? renderBoxMessage(
                  config["FEEDBACK-UI-INPUT-VALIDATION-MESSAGE"],
                  true
                )
              : ""}
            {loading ? (
              <div className="loading-container">
                <img src={loadingSvg} alt="Loading..." className="loadingSvg" />
              </div>
            ) : (
              <>
                {isData && (
                  <MeetingHeader
                    text={eventName}
                    lastName={lastName}
                    firstName={firstName}
                    description={description}
                    activityType={activityType}
                    activityDateTime={activityDateTime}
                    avatar={avatar}
                    feedbackCount={feedbackCount}
                    activityCount={activityCount}
                    level={level}
                  />
                )}
                {showConfirmation ? renderConfirmation() : renderQuestions()}
              </>
            )}
          </div>
          <ToastContainer />
        </div>
      </section>
      {/* <TopUI />
      <Activities />
      <ShareFeedback />
      <Growth />
      <Progress />
      <DownloadApp /> */}
    </main>
  );
}

export default RatingPage;
